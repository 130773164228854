import configuration from '../configuration.mjs';
import initSwedishSearchForm from './estate-search-page/swedish-search-form.mjs';
import initInternationalSearchForm from './estate-search-page/international-search-form.mjs';
import CreateSearchAlertButtonHandler from './estate-search-page/create-search-alert-button-handler.mjs';
import SearchResultHandler from './estate-search-page/search-result-handler.mjs';

/**
 * @param {Element} rootElement
 */
export default (rootElement = document.body) => {
  const pageElement = rootElement?.querySelector('#estate-search-page');

  if(!pageElement) {
    return;
  }

  if(configuration.website === 'Sweden') {
    initSwedishSearchForm(pageElement);
  } else if(configuration.website === 'International') {
    initInternationalSearchForm(pageElement);
  } else {
    throw new Error(`Unknown website: ${configuration.website}`);
  }

  new SearchResultHandler(pageElement);
  new CreateSearchAlertButtonHandler(pageElement);
};
