import '../styles/main.scss';

import initBlocks from './block-scripts/init.mjs';
import initPageScripts from './page-scripts/init.mjs';
import initPages from './pages/init.mjs';
import './utils/dialog-manager.mjs';

initPageScripts();
initBlocks();
initPages();
