import EventTarget from '@ungap/event-target';

export default class CountrySelectWrapper extends EventTarget {
  #selectElement;
  #hiddenFallbackElement;

  /**
   * @param {HTMLSelectElement} selectElement The select element
   */
  constructor(selectElement) {
    super();

    if(!(selectElement instanceof HTMLSelectElement)) {
      throw new Error('selectElement must be an HTMLElement.');
    }

    const hiddenFallbackElement = selectElement.parentElement.querySelector('input[type="hidden"]');
    if(!(hiddenFallbackElement instanceof HTMLInputElement)) {
      throw new Error('hiddenFallbackElement must be an HTMLInputElement.');
    }

    this.#selectElement = selectElement;
    this.#hiddenFallbackElement = hiddenFallbackElement;

    this.#selectElement.addEventListener('change', this.#handleSelectChange.bind(this));
  }

  #handleSelectChange() {
    const event = new CustomEvent('selected-items-changed', {
      detail: {
        sender: this
      }
    });

    this.dispatchEvent(event);
  }

  enable() {
    this.#selectElement.disabled = false;
    this.#hiddenFallbackElement.value = '';
  }

  disable() {
    this.#selectElement.disabled = true;

    const seletedOption = this.#selectElement.querySelector('option:checked');
    this.#hiddenFallbackElement.value = seletedOption.value;
  }
}
