let focusedContainer = null;

export let lockFocus = function lockFocus(element) {
  if(focusedContainer !== null) {
    throw new Error('Element is already locked.', focusedContainer);
  }

  focusedContainer = element;
  document.addEventListener('focus', focusHandler, true);
};

export let unlockFocus = function unlockFocus() {
  if(focusedContainer === null) {
    return;
  }

  document.removeEventListener('focus', focusHandler, true);
  focusedContainer = null;
};

const focusHandler = function focusHandler(event) {
  if(!focusedContainer.contains(event.target)) {
    focusedContainer.focus();
  }
};
