import Combobox from '../utils/combobox.mjs';

/**
 * @param {Element} rootElement
 */
export default function apply(rootElement = document.body) {
  const elements = rootElement.querySelectorAll('.language-selector');

  for(const containerElement of elements) {
    const comboboxElement = containerElement.querySelector('.language-selector-combobox');
    const listboxElement = containerElement.querySelector('.language-selector-listbox');

    const combobox = new Combobox(comboboxElement, listboxElement);
    combobox.addEventListener('item-selected', event => {
      const { element } = event.detail;
      const url = element.dataset.url;

      if(!!url) {
        window.location.href = url;
      }
    });
  }
}
