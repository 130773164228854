/**
 * @param {Element} rootElement
 */
export default function apply(rootElement = document.body) {
  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  if(prefersReducedMotion) {
    return;
  }

  const videos = [...rootElement.querySelectorAll('video[data-autoplay-if-allowed]')];
  for(const video of videos) {
    video.autoplay = true;
  }
}
