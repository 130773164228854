const COOKIE_TRACTOR_SCROLL_LOCK_CLASS = 'cc-active';

export default () => {
  handleCookieTractorScrollLock();
};

/**
 * Removes the class that locks site scroll when cookie tractor is active
 */
const handleCookieTractorScrollLock = () => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if(mutation.attributeName === 'class' && mutation.target.classList.contains(COOKIE_TRACTOR_SCROLL_LOCK_CLASS)) {
        mutation.target.classList.remove(COOKIE_TRACTOR_SCROLL_LOCK_CLASS);
      }
    });
  });

  observer.observe(document.documentElement, { attributes: true });
};
