import postbox from '../../postbox.mjs';
import configuration from '../../configuration.mjs';
import { getPageLanguage } from '../../utils/get-page-language.mjs';

const BiddingRefreshIntervalInMilliseconds = 5_000;

let updateHandle = null;
let loading = false;

const updateBiddingInformation = async() => {
  if(loading) {
    return;
  }

  loading = true;

  try {
    const pageLanguage = getPageLanguage();
    const estateId = configuration.estate.shortId;
    const response = await fetch(`/api/${pageLanguage}/estate/${estateId}/bidding`);

    if(!response.ok) {
      return;
    }

    const biddingHtml = await response.text();

    const biddingDialogContent = document.querySelector('#bidding-dialog .inner-container');

    let bidContainer = biddingDialogContent.querySelector('.bids');
    const currentScrollPosition = bidContainer?.scrollTop;

    biddingDialogContent.innerHTML = biddingHtml;

    bidContainer = biddingDialogContent.querySelector('.bids');
    if(!!bidContainer) {
      bidContainer.scrollTop = currentScrollPosition;
    }
  } finally {
    loading = false;
  }
};

const dialogOpened = ({ detail: { dialog } }) => {
  if(dialog.element.id !== 'bidding-dialog') {
    return;
  }

  updateHandle = setInterval(updateBiddingInformation, BiddingRefreshIntervalInMilliseconds);
};

const dialogClosed = ({ detail: { dialog } }) => {
  if(dialog.element.id !== 'bidding-dialog') {
    return;
  }

  if(!!updateHandle) {
    clearInterval(updateHandle);
  }
};

export default () => {
  if(!configuration.estate.showBids) {
    return;
  }

  postbox.addEventListener('dialog-opened', dialogOpened);
  postbox.addEventListener('dialog-closed', dialogClosed);
};
