/* global $$epiforms */
//import { WidgetInstance } from 'friendly-challenge';
import configuration from '../../configuration.mjs';
import { verifyPropertyExists } from '../../utils/verify-property-exists.mjs';

const widgets = [];

export default async() => {
  if(!verifyPropertyExists(window, 'epi', 'EPiServer', 'Forms')) {
    return;
  }

  if(typeof $$epiforms !== 'function') {
    console.warn('Could not find $$epiforms function. Friendly Captcha will not be initialized.');
    return;
  }

  $$epiforms.extend(true, window.epi.EPiServer.Forms, {
    Validators: {
      'SkandiaMaklarna.Web.Core.Forms.Validators.FriendlyCaptchaElementValidator': function() {
        return { isValid: true };
      }
    }
  });

  let WidgetInstance = null;

  const friendlyCaptchaElements = [...document.querySelectorAll('div[data-friendly-captcha]')];
  for(const friendlyCaptchaElement of friendlyCaptchaElements) {
    const siteKey = configuration.friendlyCaptcha.siteKey;

    const targetElementId = friendlyCaptchaElement.dataset.friendlyCaptchaTargetElementId;
    const targetElement = document.getElementById(targetElementId);

    const formElement = friendlyCaptchaElement.closest('.EPiServerForms');
    if(!formElement) {
      console.warn('Could not find form element for friendly captcha element.', friendlyCaptchaElement);
      continue;
    }

    const submitButton = formElement.querySelector('button[data-f-type="submitbutton"]');
    if(!!submitButton) {
      submitButton.disabled = true;
    } else {
      console.warn('Could not find submit button for friendly captcha element. If it exists it won\'t be disabled.', friendlyCaptchaElement);
    }

    let puzzleEndpoint = 'https://api.friendlycaptcha.com/api/v1/puzzle';
    if(configuration.friendlyCaptcha.useEUEndpoint) {
      puzzleEndpoint = 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle';
    }

    if(!WidgetInstance) {
      // Only load this if actually needed
      ({ WidgetInstance } = await import('friendly-challenge'));
    }

    const widget = new WidgetInstance(friendlyCaptchaElement, {
      sitekey: siteKey,
      language: configuration.culture,
      puzzleEndpoint: puzzleEndpoint,
      solutionFieldName: '-', // Do not create a hidden input field
      skipStyleInjection: false,
      doneCallback: token => {
        if(!targetElement) {
          return;
        }

        targetElement.value = token;

        if(!!submitButton) {
          submitButton.disabled = false;
        }
      }
    });

    $$epiforms(formElement).on('formsSubmitted', e => {
      if(e.isSuccess) {
        return;
      }

      if(!!submitButton) {
        // This needs a short delay since this happens before the form itself
        // tries to re-enable the submit button.
        window.setTimeout(() => {
          submitButton.disabled = true;
        }, 100);
      }

      console.info('Form submit failed. Resetting Friendly Captcha widget.');
      widget.reset();
      widget.start();
    });

    widgets.push({ widget, formElement });
  }
};

export const destroy = () => {
  for(const { widget, formElement } of [...widgets]) {
    widget.destroy();
    widgets.splice(widgets.indexOf(widget), 1);

    $$epiforms(formElement).off('formsSubmitted');
  }
};
