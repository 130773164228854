import Combobox from '../../utils/combobox.mjs';
import { firstAnscestorOrDefault } from '../../utils/dom-utils.mjs';
import { getPageLanguage } from '../../utils/get-page-language.mjs';
import localeComparer from '../../utils/locale-comparer.mjs';

export default class InternationalAreaCombobox extends Combobox {
  #inputContainerElement;
  #disabled = false;

  #listboxElement;

  /**
   * @param {HTMLElement} comboboxElement The combobox element
   * @param {HTMLElement} listboxElement The listbox element
   * @param {HTMLElement} inputContainerElement The input container element
   */
  constructor(comboboxElement, listboxElement, inputContainerElement) {
    super(comboboxElement, listboxElement);

    if(!(inputContainerElement instanceof HTMLElement)) {
      throw new Error('inputContainerElement must be an HTMLElement.');
    }

    this.#inputContainerElement = inputContainerElement;
    this.#listboxElement = listboxElement;

    this.#disabled = this.#inputContainerElement.classList.contains('disabled');

    this.#setupEventListeners();
  }

  get hasSelectedItems() {
    return this.selectedInputs.length > 0;
  }

  get selectedInputs() {
    return [...this.#inputContainerElement.querySelectorAll('.multiselect-selected-options input:checked')];
  }

  #setupEventListeners() {
    const inputContainer = this.#inputContainerElement;

    inputContainer.addEventListener('change', event => {
      const input = event.target;
      if(!(input instanceof HTMLInputElement)) {
        throw new Error('input must be an HTMLInputElement.');
      }

      if(input.type !== 'checkbox') {
        return;
      }

      if(input.readOnly) {
        input.checked = true;
        event.stopPropagation();
        return;
      }

      const multiselectOptionId = input.dataset.multiselectOptionId;
      if(!multiselectOptionId) {
        throw new Error('multiselectOptionId must be set.');
      }

      // This one isn't always available due to dynamicly removed items.
      const listboxItem = this.listboxElement.querySelector(`#${multiselectOptionId}`);
      listboxItem?.setAttribute('aria-selected', 'false');

      const inputParentListItem = firstAnscestorOrDefault(input, x => x.tagName === 'LI');
      inputParentListItem.remove();

      this.#dispatchChangeEvent();
    });

    this.addEventListener('item-selected', event => {
      const element = event.detail.element;

      const { filterField, filterLabel, filterValue } = element.dataset;

      const templateElement = this.#inputContainerElement.querySelector('template[data-template-name="selected-filter"]');
      const templateRoot = templateElement.content.cloneNode(true);

      const input = templateRoot.querySelector('input');
      input.name = filterField;
      input.value = filterValue;
      input.dataset.multiselectOptionId = element.id;

      const label = templateRoot.querySelector('span');
      label.textContent = filterLabel;

      const selectedOptionsList = this.#inputContainerElement.querySelector('.multiselect-selected-options');

      const language = getPageLanguage();
      const options = [...selectedOptionsList.children];

      let insertAtIndex = 0;
      for(const [index, option] of options.entries()) {
        const textContent = option.textContent.trim();

        const compareResult = localeComparer(textContent, filterValue, language);

        if(compareResult !== 1) {
          insertAtIndex = index + 1;
        }
      }

      selectedOptionsList.insertBefore(templateRoot, selectedOptionsList.children[insertAtIndex]);

      this.#dispatchChangeEvent();
    });

    this.addEventListener('item-deselected', event => {
      const element = event.detail.element;

      const optionId = element.id;
      if(!optionId) {
        throw new Error('option id must be set.');
      }

      const input = this.#inputContainerElement.querySelector(`input[data-multiselect-option-id="${optionId}"]`);
      if(!input) {
        throw new Error(`input with data-multiselect-option-id ${optionId} not found.`);
      }

      const inputParentListItem = firstAnscestorOrDefault(input, x => x.tagName === 'LI');
      inputParentListItem.remove();

      this.#dispatchChangeEvent();
    });
  }

  updateItems(items) {
    const listboxElement = this.#listboxElement;
    while(!!listboxElement.firstChild) {
      listboxElement.removeChild(listboxElement.lastChild);
    }

    this.#createItemGroups(listboxElement, items);
  }

  #createItemGroups(listboxElement, groups) {
    const templateElement = this.#inputContainerElement.parentElement.querySelector('template[data-template-name="filter-group"]');

    for(const group of groups) {
      const templateRoot = templateElement.content.cloneNode(true);

      const list = templateRoot.querySelector('ul');
      list.setAttribute('aria-labelledby', `multiselect-group-${group.groupId}`);

      const label = list.querySelector('.group-label');
      label.id = `multiselect-group-${group.groupId}`;
      label.textContent = group.label;

      this.#createItemsInGroup(list, group.values);

      listboxElement.appendChild(templateRoot);
    }
  }

  #createItemsInGroup(groupElement, items) {
    const templateElement = this.#inputContainerElement.parentElement.querySelector('template[data-template-name="filter-item"]');

    for(const item of items) {
      const templateRoot = templateElement.content.cloneNode(true);

      const listItem = templateRoot.querySelector('li');
      listItem.id = `multiselect-list-item-${item.filterId}`;
      listItem.dataset.filterField = item.filterField;
      listItem.dataset.filterLabel = item.label;
      listItem.dataset.filterValue = item.value;
      listItem.setAttribute('aria-selected', item.selected ? 'true' : 'false');

      const label = listItem.querySelector('.label-text');
      label.textContent = item.label;

      groupElement.appendChild(templateRoot);
    }
  }

  enable() {
    if(!this.#disabled) {
      return;
    }

    this.#disabled = false;
    this.#updateDisabledAttributes();
  }

  disable() {
    if(this.#disabled) {
      return;
    }

    this.#disabled = true;
    this.#updateDisabledAttributes();
  }

  #dispatchChangeEvent() {
    const event = new CustomEvent('selected-items-changed', {
      detail: {
        sender: this
      }
    });

    this.dispatchEvent(event);
  }

  #updateDisabledAttributes() {
    const disabled = this.#disabled;
    const inputContainerElement = this.#inputContainerElement;

    const comboboxElement = inputContainerElement.querySelector('.multiselect-combobox');
    const selectedOptions = inputContainerElement.querySelectorAll('.multiselect-selected-options input');

    if(disabled) {
      inputContainerElement.classList.add('disabled');
      comboboxElement.setAttribute('aria-disabled', 'true');
    } else {
      inputContainerElement.classList.remove('disabled');
      comboboxElement.setAttribute('aria-disabled', 'false');
    }

    for(const selectedOption of selectedOptions) {
      selectedOption.readOnly = disabled;
    }
  }
}
