import { getThumbnailUrl } from '../utils/vimeo-thumbnail-cache.mjs';
import { VimeoVideo } from '../utils/vimeo-video.mjs';

/**
 * @param {Element} rootElement
 */
export default (rootElement = document.body) => {
  const blocks = rootElement.querySelectorAll('.guide-step-block');
  for(const block of blocks) {
    setupBlock(block);
  }
};

const setupBlock = block => {
  const videoSection = block.querySelector('.step-video');

  const { videoSrc } = videoSection.dataset;
  if(!!videoSrc) {
    insertThumbnailImage(videoSection, videoSrc);
  }

  setupEventHandlers(block);
};

const insertThumbnailImage = async(container, videoSrc) => {
  const thumbnailUrl = await getThumbnailUrl(videoSrc);
  if(!thumbnailUrl) {
    container.remove();
  }

  const img = document.createElement('img');
  img.src = thumbnailUrl;
  img.classList.add('thumbnail');
  img.setAttribute('alt', '');
  img.setAttribute('role', 'presentation');

  const playButton = container.querySelector('.play-button');
  playButton.appendChild(img);
};

const setupEventHandlers = (block) => {
  const playButton = block.querySelector('.play-button');
  playButton.addEventListener('click', () => {
    const videoSection = block.querySelector('.step-video');
    const { videoSrc } = videoSection.dataset;
    if(!videoSrc) {
      return;
    }

    createPlayer(block, true);
  });

  const closeButton = block.querySelector('.close-button');
  closeButton.addEventListener('click', () => {
    closeOverlay(block);
    destroyPlayer(block);
  });
};

const createPlayer = (block, autoplay) => {
  const videoSection = block.querySelector('.step-video');
  const { videoSrc } = videoSection.dataset;

  const vimeoVideo = VimeoVideo.tryParse(videoSrc);
  if(!vimeoVideo) {
    return;
  }

  hideStaticElements(block);
  openOverlay(block);

  const iframeContainer = videoSection.querySelector('.iframe-container');

  const loader = document.createElement('div');
  loader.classList.add('loader');
  iframeContainer.appendChild(loader);

  const iframe = document.createElement('iframe');
  iframe.setAttribute('allow', 'fullscreen; autoplay;');
  iframe.setAttribute('allowfullscreen', '');
  iframe.setAttribute('src', vimeoVideo.generateEmbedUrl(autoplay));

  iframeContainer.appendChild(iframe);

  const messageCallback = message => {
    if(message.source !== iframe.contentWindow) {
      return;
    }

    const data = JSON.parse(message.data);
    if(data.event !== 'ready' && data.event !== 'ping') {
      return;
    }

    loader.remove();
    window.removeEventListener('message', messageCallback);
  };

  window.addEventListener('message', messageCallback);
  iframe.contentWindow.postMessage({ method: 'ping' }, '*');
};

const destroyPlayer = block => {
  const videoSection = block.querySelector('.step-video');
  const iframeContainer = videoSection.querySelector('.iframe-container');

  const iframe = iframeContainer.querySelector('iframe');
  iframe.remove();

  showStaticElements(block);
};

const hideStaticElements = block => {
  const playButton = block.querySelector('.play-button');
  playButton.classList.add('hidden');

  const thumbnail = block.querySelector('.step-video img');
  thumbnail.classList.add('hidden');
};

const showStaticElements = block => {
  const playButton = block.querySelector('.play-button');
  playButton.classList.remove('hidden');

  const thumbnail = block.querySelector('.step-video img');
  thumbnail.classList.remove('hidden');
};

const openOverlay = block => {
  const videoContainer = block.querySelector('.video-container');
  videoContainer.classList.add('open');
};

const closeOverlay = block => {
  const videoContainer = block.querySelector('.video-container');
  videoContainer.classList.remove('open');
};
