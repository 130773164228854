import InternationalAreaCombobox from './international-area-combobox.mjs';
import SearchFormHandlerBase from './search-form-handler-base.mjs';
import { getPageLanguage } from '../../utils/get-page-language.mjs';
import { isNullOrWhiteSpace } from '../../utils/string-utils.mjs';

export default class InternationalSearchFormHandler extends SearchFormHandlerBase {
  /**
   * @type {InternationalAreaCombobox[]}
   */
  #multiselects = [];

  /**
   * @type {AbortController}
   */
  #abortController = null;

  constructor(containerElement, multiselects) {
    super(containerElement);

    if(!Array.isArray(multiselects)) {
      throw new Error('multiselects must be provided as an array.');
    }

    this.#multiselects = multiselects;

    this.#init();
  }

  #init() {
    const multiselects = this.#multiselects;

    for(const { combobox } of multiselects) {
      combobox.addEventListener('selected-items-changed', this.handleFormChange.bind(this));
      combobox.addEventListener('selected-items-changed', this.#updateAvailability.bind(this));
      combobox.addEventListener('selected-items-changed', this.#fetchNewSuggestions.bind(this));
    }

    const referenceNumberButton = document.getElementById('search-reference-number-button');
    referenceNumberButton.addEventListener('click', this.#handleReferenceNumberButtonClick.bind(this));
  }

  /**
   * @param {Event} event
   */
  async #handleReferenceNumberButtonClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const errorMessageElement = document.getElementById('estate-not-found-message');
    errorMessageElement.classList.remove('show');

    const referenceNumberInput = document.getElementById('reference-number');
    const referenceNumber = referenceNumberInput.value;
    if(isNullOrWhiteSpace(referenceNumber)) {
      return;
    }

    let abortController = this.#abortController;
    if(abortController !== null) {
      abortController.abort();
    }

    this.#abortController = abortController = new AbortController();

    const culture = getPageLanguage();
    const url = `/api/${culture}/estate-search/by-reference-number/${encodeURIComponent(referenceNumber)}`;

    let response;
    try {
      response = await fetch(url, { signal: abortController.signal });
    } catch(error) {
      if(error.name === 'AbortError') {
        return;
      }

      throw error;
    }

    if(!response.ok) {
      if(response.status === 404) {
        errorMessageElement.classList.add('show');
        return;
      }

      console.error(`Failed to fetch estate by reference number: ${response.status} ${response.statusText}`);
      return;
    }

    const result = await response.text();
    window.location.href = result;
  }

  getUrlParts(formData) {
    return [
      this.#getCountryForUrl(),
      this.getEstateTypeForUrl(formData),
      ...this.#getGeographicalFiltersForUrl(formData)
    ];
  }

  #getCountryForUrl() {
    const countryElement = document.getElementById('country');

    const selectedOption = countryElement.querySelector('option:checked');
    if(!selectedOption) {
      return null;
    }

    return selectedOption.textContent;
  }

  #getGeographicalFiltersForUrl(formData) {
    const province = this.#getGeographicalFilterlabel(formData, 'foreignGeoDataProvince');
    const city = this.#getGeographicalFilterlabel(formData, 'foreignGeoDataCity');
    const cityArea = this.#getGeographicalFilterlabel(formData, 'foreignGeoDataCityArea');

    const result = [];

    if(!!province) {
      result.push(province);
    }

    if(!!city) {
      result.push(city);
    }

    if(!!cityArea) {
      result.push(cityArea);
    }

    return result;
  }

  #getGeographicalFilterlabel(formData, field) {
    const value = formData.get(field);
    if(!value) {
      return null;
    }

    const selectedInputs = this.#multiselects.flatMap(x => x.combobox.selectedInputs).filter(x => !!x);
    const selectedInput = selectedInputs.find(x => x.name === field && x.value === value);
    if(!selectedInput) {
      return value;
    }

    return selectedInput.parentElement.textContent.trim();
  }

  #getFormData() {
    const form = this.form;
    const formData = new FormData(form);

    const queryFields = [
      'country',
      'foreignGeoDataProvince',
      'foreignGeoDataCity',
      'foreignGeoDataCityArea'
    ];

    for(const [ field ] of [...formData]) {
      if(!queryFields.includes(field)) {
        formData.delete(field);
      }
    }

    return formData;
  }

  #updateAvailability() {
    for(const { combobox, dependsOn } of this.#multiselects) {
      const shouldBeDisabled = dependsOn.some(x => x.hasSelectedItems);
      if(shouldBeDisabled) {
        combobox.disable();
      } else {
        combobox.enable();
      }
    }
  }

  async #fetchNewSuggestions() {
    let abortController = this.#abortController;
    if(abortController !== null) {
      abortController.abort();
    }

    this.#abortController = abortController = new AbortController();

    const culture = getPageLanguage();
    const formData = this.#getFormData();
    const url = `/api/${culture}/estate-search/foreign-areas?${new URLSearchParams(formData)}`;

    let response;
    try {
      response = await fetch(url, { signal: abortController.signal });
    } catch(error) {
      if(error.name === 'AbortError') {
        return;
      }

      throw error;
    }

    if(!response.ok) {
      console.error(`Failed to fetch autocomplete result: ${response.status} ${response.statusText}`);
      return;
    }

    const result = await response.json();

    const provinceCombobox = this.#multiselects.find(x => x.name === 'province').combobox;
    provinceCombobox.updateItems(result.provinces);

    const cityCombobox = this.#multiselects.find(x => x.name === 'city').combobox;
    cityCombobox.updateItems(result.cities);

    const cityAreaCombobox = this.#multiselects.find(x => x.name === 'city-area').combobox;
    cityAreaCombobox.updateItems(result.cityAreas);
  }
}
