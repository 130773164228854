export let generateUUID = function generateUUID() {
  // The "4" in the below string says that this UUID was randomly generated
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(char) {
    if(char === 'y') {
      const allowedYValues = ['8', '9', 'a', 'b'];
      return allowedYValues[Math.floor(Math.random() * allowedYValues.length)];
    }

    const array = new Uint8Array(1);
    window.crypto.getRandomValues(array);
    return (array[0] % 16).toString(16);
  });
};
