import { firstAnscestorOrDefault } from '../utils/dom-utils.mjs';
import postbox from '../postbox.mjs';

/**
 * @param {Element} rootElement
 */
export default function apply(rootElement = document.body) {
  const handleKeydown = (/** @type {KeyboardEvent} */ event) => {
    const targetElement = event.target;
    if(!isInTablist(targetElement)) {
      return;
    }

    const tablist = getTablist(targetElement);
    let handled = false;

    switch (event.key) {
      case 'ArrowLeft':
        selectPreviousTab(tablist);
        handled = true;
        break;
      case 'ArrowRight':
        selectNextTab(tablist);
        handled = true;
        break;
      case 'Home':
        selectTab(getFirstTab(tablist));
        handled = true;
        break;
      case 'End':
        selectTab(getLastTab(tablist));
        handled = true;
        break;
      default:
        break;
    }

    if(handled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleClick = (/** @type {MouseEvent} */ event) => {
    const targetElement = event.target;
    if(!isInTablist(targetElement)) {
      return;
    }

    const tab = firstAnscestorOrDefault(targetElement, element => element.matches('[role="tab"]'), true);
    selectTab(tab);
  };

  rootElement.addEventListener('click', handleClick);
  rootElement.addEventListener('keydown', handleKeydown);
}

export let getTablist = (/** @type {HTMLElement} */ element) => {
  return firstAnscestorOrDefault(element, element => element.matches('[role="tablist"]'), true);
};

export let isInTablist = (/** @type {HTMLElement} */ element) => {
  return !!getTablist(element);
};

export let getCurrentTab = (/** @type {HTMLElement} */ tablist) => {
  return tablist.querySelector('[role="tab"][aria-selected="true"]');
};

export let getCurrentTabPanel = (/** @type {HTMLElement} */ tablist) => {
  const currentTab = getCurrentTab(tablist);
  if(!currentTab) {
    return null;
  }

  const tabId = currentTab.getAttribute('aria-controls');
  return document.querySelector(`[role="tabpanel"][id="${tabId}"]`);
};

export let getFirstTab = (/** @type {HTMLElement} */ tablist) => {
  return tablist.querySelector('[role="tab"]');
};

export let getLastTab = (/** @type {HTMLElement} */ tablist) => {
  const tabs = tablist.querySelectorAll('[role="tab"]');
  return tabs[tabs.length - 1];
};

export let getNextTab = (/** @type {HTMLElement} */ tab) => {
  let nextSibling;
  do {
    nextSibling = tab.nextElementSibling;
  } while(nextSibling && nextSibling.getAttribute('role') !== 'tab');

  return nextSibling;
};

export let getPreviousTab = (/** @type {HTMLElement} */ tab) => {
  let previousSibling;
  do {
    previousSibling = tab.previousElementSibling;
  } while(previousSibling && previousSibling.getAttribute('role') !== 'tab');

  return previousSibling;
};

export let selectTab = (/** @type {HTMLElement} */ tab, skipFocus = false) => {
  if(!tab) {
    return;
  }

  const tablist = getTablist(tab);

  const tabId = tab.getAttribute('aria-controls');
  const panel = document.querySelector(`[role="tabpanel"][id="${tabId}"]`);
  if(!panel) {
    return;
  }

  const currentTab = getCurrentTab(tablist);
  const currentPanel = getCurrentTabPanel(tablist); // This needs to be fetched before the current tab is deselected

  if(!!currentTab) {
    currentTab.setAttribute('aria-selected', 'false');
    currentTab.setAttribute('tabindex', '-1');
  }

  if(!!currentPanel) {
    currentPanel.setAttribute('aria-hidden', 'true');
    currentPanel.inert = true;
  }

  tab.setAttribute('aria-selected', 'true');
  tab.removeAttribute('tabindex');

  if(!skipFocus) {
    tab.focus();
  }

  panel.setAttribute('aria-hidden', 'false');
  panel.inert = false;

  postbox.dispatchEvent(new CustomEvent('tab-selected', { detail: { tablist, tab, panel } }));
};

const selectPreviousTab = (/** @type {HTMLElement} */ tablist) => {
  const currentTab = getCurrentTab(tablist);
  if(!currentTab) {
    return;
  }

  let previousTab = getPreviousTab(currentTab);
  if(!previousTab) {
    previousTab = getLastTab(tablist);
  }

  selectTab(previousTab);
};

export let selectNextTab = (/** @type {HTMLElement} */ tablist) => {
  const currentTab = getCurrentTab(tablist);
  if(!currentTab) {
    return;
  }

  let nextTab = getNextTab(currentTab);
  if(!nextTab) {
    nextTab = getFirstTab(tablist);
  }

  selectTab(nextTab);
};
