import initFormBlockBase from './form-blocks.mjs';
import initFriendlyCaptcha from './forms/friendly-captcha.mjs';
import initGuideBlocks from './guide-block.mjs';

/**
 * Init all block scripts
 */
export default function() {
  initFormBlockBase();
  initFriendlyCaptcha();
  initGuideBlocks();
}
