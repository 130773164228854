import { firstAnscestorOrDefault } from './dom-utils.mjs';
import Dialog from './dialog.mjs';
import postbox from '../postbox.mjs';

export class DialogManager {
  /**
   * @type {Dialog[]}
   */
  #dialogs = [];

  #handleClickBound = null;

  constructor() {
    this.#handleClickBound = this.#handleClick.bind(this);

    this.#setupEventListeners();
    this.#registerDialogs();
  }

  reset() {
    for(const dialog of this.#dialogs) {
      dialog.destroy();
    }

    this.#dialogs = [];
  }

  #setupEventListeners() {
    document.addEventListener('click', this.#handleClickBound);
  }

  dispose() {
    this.reset();
    document.removeEventListener('click', this.#handleClickBound);
  }

  #registerDialogs() {
    const dialogElements = document.querySelectorAll('[data-register-dialog]');
    for(const element of dialogElements) {
      this.addDialog(element);
    }
  }

  #handleClick(event) {
    const targetElement = event.target;
    const elementWithDialogActivator = firstAnscestorOrDefault(targetElement, element => typeof element.dataset.dialogActivator !== 'undefined', true);
    const activatorElementId = elementWithDialogActivator?.dataset.dialogActivator;

    if(!!activatorElementId) {
      const dialogElement = document.getElementById(activatorElementId);
      if(!!dialogElement) {
        this.openDialog(dialogElement, elementWithDialogActivator);
      }
    }

    const elementWithDialogClose = firstAnscestorOrDefault(targetElement, element => typeof element.dataset.dialogCloseButton !== 'undefined', true);
    const closeElementId = elementWithDialogClose?.dataset.dialogCloseButton;

    if(!!closeElementId) {
      const dialogElement = document.getElementById(closeElementId);
      if(!!dialogElement) {
        this.closeDialog(dialogElement);
      }
    }
  }

  addDialog(targetElement) {
    const dialog = new Dialog(targetElement);
    this.#dialogs.push(dialog);
  }

  openDialog(targetElement, sourceElement) {
    const dialog = this.#dialogs.find(dialog => dialog.element === targetElement);
    if(!dialog) {
      console.warn('No dialog found for targetElement.', targetElement);
      return;
    }

    dialog.open(sourceElement);

    postbox.dispatchEvent(new CustomEvent('dialog-opened', { detail: { dialog, sourceElement } }));
  }

  closeDialog(targetElement) {
    const dialog = this.#dialogs.find(dialog => dialog.element === targetElement);
    if(!dialog) {
      console.warn('No dialog found for targetElement.', targetElement);
      return;
    }

    dialog.close();

    postbox.dispatchEvent(new CustomEvent('dialog-closed', { detail: { dialog } }));
  }
}

export default new DialogManager();
