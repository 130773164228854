import TagManager from '../utils/google-tag-manager.mjs';
import configuration from '../configuration.mjs';

/**
 * @param {Element} rootElement
 */
export default function apply(rootElement = document.body) {
  rootElement.addEventListener('click', eventHandler);
  rootElement.addEventListener('copy', eventHandler);
}

export let eventHandler = (/** @type {Event} */ event) => {
  const { target } = event;

  if(!isContactLink(target)) {
    return;
  }

  const contactType = target.getAttribute('data-contact-request-trigger') || 'unknown';

  const properties = {
    officeId: configuration.office?.id,
    officeName: configuration.office?.name,
    brokerId: configuration.broker?.id,
    brokerName: configuration.broker?.name,
    estateId: configuration.estate?.id,
    estateShortId: configuration.estate?.shortId,
    culture: configuration.culture,
    contactType: contactType,
    eventType: event.type
  };

  TagManager.trackEvent('contact-link-clicked', properties);
};

export let isContactLink = (/** @type {HTMLElement} */ element) => {
  if(element.tagName !== 'A') {
    return false;
  }

  if(!element.hasAttribute('data-contact-request-trigger')) {
    return false;
  }

  const href = element.getAttribute('href');
  if(!href || (!href.startsWith('mailto:') && !href.startsWith('tel:'))) {
    return false;
  }

  return true;
};

