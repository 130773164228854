/**
 * Checks if a string is null or empty or if it only consists of whitespace.
 * @param {string} input The string to test.
 * @returns {boolean} True if the string is null or empty or if it only consists of whitespace, otherwise false.
 * @example
 * isNullorWhiteSpace() // true
 * isNullorWhiteSpace('') // true
 * isNullorWhiteSpace(' ') // true
 * isNullorWhiteSpace('\t') // true
 * isNullorWhiteSpace(null) // true
 * isNullorWhiteSpace('foo') // false
 * isNullorWhiteSpace(' foo ') // false
 */
export let isNullOrWhiteSpace = input => {
  return !input || input.match(/^\s*$/) !== null;
};
