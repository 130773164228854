import Combobox from '../../utils/combobox.mjs';
import { debounce } from '../../utils/debounce.mjs';
import { generateUUID } from '../../utils/generate-uuid.mjs';
import { getPageLanguage } from '../../utils/get-page-language.mjs';

const SEARCH_DEBOUNCE_TIMEOUT = 50;
const MINIMUM_NUMBER_OF_CHARS = 3;

export default class FreetextAutocompleteCombobox extends Combobox {
  /**
   * @type {AbortController}
   */
  #abortController = null;
  #debouncedFetchAutocomplete = debounce(this.#fetchSuggestions.bind(this), SEARCH_DEBOUNCE_TIMEOUT);

  /**
   * @param {HTMLElement} comoboxElement
   * @param {HTMLElement} listboxElement
   */
  constructor(comoboxElement, listboxElement) {
    super(comoboxElement, listboxElement);

    this.#init();
  }

  #init() {
    this.comboboxElement.addEventListener('input', e => {
      const searchTerm = e.target.value;
      if(searchTerm.length < MINIMUM_NUMBER_OF_CHARS) {
        this.close();
        return;
      }

      this.#debouncedFetchAutocomplete(searchTerm, result => this.#updateSuggestions(result));
    });

    this.addEventListener('item-selected', this.#handleItemSelected.bind(this));
  }

  #reset() {
    this.comboboxElement.value = '';
    this.listboxElement.innerHTML = '';

    this.close();
  }

  #handleItemSelected(event) {
    const itemElement = event.detail.element;

    const field = itemElement.dataset.field;
    if(field === 'Estate') {
      const estateId = itemElement.dataset.estateId;
      window.location.href = `?estateId=${estateId}`;
      return;
    }

    const addFilterEvent = new CustomEvent('add-filter', {
      detail: {
        field: itemElement.dataset.field,
        value: itemElement.dataset.value,
        label: itemElement.dataset.label
      }
    });

    this.dispatchEvent(addFilterEvent);

    this.#reset();
  }

  async #fetchSuggestions(searchTerm, callback) {
    if(this.#abortController !== null) {
      this.#abortController.abort();
    }

    this.#abortController = new AbortController();
    const signal = this.#abortController.signal;

    const culture = getPageLanguage();
    const url = `/api/${culture}/estate-search/autocomplete?term=${encodeURIComponent(searchTerm)}`;

    let response;
    try {
      response = await fetch(url, { signal });
    } catch(error) {
      if(error.name === 'AbortError') {
        return;
      }

      throw error;
    }

    if(!response.ok) {
      console.error(`Failed to fetch autocomplete result: ${response.status} ${response.statusText}`);
      return;
    }

    const result = await response.json();
    callback(result);
  }

  #updateSuggestions(result) {
    if(!result) {
      return;
    }

    let suggestions = [
      ...result.areas,
      ...result.counties,
      ...result.municipalities,
      ...result.estateLinks
    ]
      .map(suggestion => ({
        ...suggestion,
        completeLabel: !!suggestion.secondaryLabel ? `${suggestion.label} (${suggestion.secondaryLabel})` : suggestion.label,
        uuid: generateUUID()
      }));

    this.#renderSuggestions(suggestions);
  }

  #renderSuggestions(suggestions = []) {
    const comboboxElement = this.comboboxElement;
    const listboxElement = this.listboxElement;

    listboxElement.innerHTML = '';

    if(comboboxElement.value.length < MINIMUM_NUMBER_OF_CHARS) {
      return;
    }

    if(suggestions.length === 0 && comboboxElement.value.length >= MINIMUM_NUMBER_OF_CHARS) {
      const listItem = document.createElement('li');
      listItem.innerText = listboxElement.dataset.noSuggestionsText;
      listItem.setAttribute('role', 'option');
      listItem.setAttribute('aria-selected', 'false');
      listItem.setAttribute('aria-disabled', 'true');
      listItem.classList.add('no-results');

      listboxElement.appendChild(listItem);
      return;
    }

    const getPriceText = (estateLink) => {
      if(!estateLink.price) {
        return '';
      }

      const formattedPrice = estateLink.price.toLocaleString('sv-SE');

      if(estateLink.currency === 'EUR') {
        return `€${formattedPrice}`;
      } else {
        return `${formattedPrice} kr`;
      }
    };

    suggestions.forEach(item => {
      const listItem = document.createElement('li');
      listItem.id = `autocomplete-item-${item.uuid}`;
      listItem.dataset.uuid = item.uuid;
      listItem.setAttribute('role', 'option');

      if(item.field === 'Estate') {
        listItem.classList.add('estate-link');

        if(!!item.imageUrl) {
          const img = document.createElement('img');
          img.src = item.imageUrl + '?width=100&height=100&crop=1';
          listItem.appendChild(img);
        }

        const address = document.createElement('span');
        address.classList.add('address');
        address.innerText = [ item.streetAddress, item.city].join(', ');
        listItem.appendChild(address);

        const price = document.createElement('span');
        price.classList.add('price');
        price.innerText = getPriceText(item);
        listItem.appendChild(price);

        listItem.dataset.field = item.field;
        listItem.dataset.estateId = item.id;
      } else {
        listItem.innerText = item.completeLabel;

        listItem.dataset.field = item.field;
        listItem.dataset.value = item.value;
        listItem.dataset.label = item.completeLabel;
      }

      listboxElement.appendChild(listItem);
    });

    this.open();
  }
}
