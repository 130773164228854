// Polyfill needeed for Safari 13...
import EventTarget from '@ungap/event-target';
import { isNullOrWhiteSpace } from '../../utils/string-utils.mjs';
import postbox from '../../postbox.mjs';

export default class SearchFormHandler extends EventTarget {
  /**
   * @type {HTMLFormElement}
   */
  #form;

  #currentPageIndex = 0;
  #currentPageSize = 18;

  constructor(containerElement) {
    super();

    this.#form = containerElement.querySelector('form');
    this.#currentPageIndex = this.#form.dataset.startingPageIndex || 0;
    this.#currentPageSize = this.#form.dataset.pageSize || 18;

    this.#init();
  }

  get form() {
    return this.#form;
  }

  #init() {
    this.#form.addEventListener('change', this.handleFormChange.bind(this));
    this.#form.addEventListener('submit', this.#handleFormSubmit.bind(this));

    postbox.addEventListener('request-next-page', this.#handleRequestNextPage.bind(this));
  }

  #handleFormSubmit(event) {
    event.preventDefault();
  }

  #handleRequestNextPage() {
    this.#currentPageIndex++;

    const formData = this.#getCleanFormData();

    formData.set('pageIndex', this.#currentPageIndex.toString());

    postbox.dispatchEvent(new CustomEvent('search-query-changed', { detail: { query: formData } }));

    this.#updateRestoreToQueryString();
  }

  #updateRestoreToQueryString() {
    const formData = this.#getCleanFormData();
    const pageIndex = this.#currentPageIndex;
    const pageSize = this.#currentPageSize;

    formData.set('restoreTo', (pageIndex + 1) * pageSize);
    formData.delete('pageIndex');

    const restoreUrl = this.#buildUrl(formData);

    this.#updateUrl(restoreUrl);
  }

  handleFormChange() {
    this.#currentPageIndex = 0;

    const formData = this.#getCleanFormData();

    const url = this.#buildUrl(formData);

    this.#updateUrl(url);

    postbox.dispatchEvent(new CustomEvent('search-query-changed', { detail: { query: formData } }));
  }

  #updateUrl(url) {
    const state = { url };
    const title = window.title;
    history.replaceState(state, title, url);
  }

  #getCleanFormData() {
    const formData = new FormData(this.#form);

    const keys = [...formData.keys()];

    for(const key of keys) {
      const value = formData.get(key);
      if(isNullOrWhiteSpace(value)) {
        formData.delete(key);
      }
    }

    return formData;
  }

  #buildUrl(formData) {
    const baseUrl = this.#form.action;
    const urlParts = this.getUrlParts(formData)
      .filter(x => !isNullOrWhiteSpace(x))
      .map(x => encodeURIComponent(x));

    const querystring = this.#toQueryString(formData);

    return baseUrl + urlParts.join('/') + querystring;
  }

  // eslint-disable-next-line no-unused-vars
  getUrlParts(formData) {
    console.warn('Should be implemented by subclass.');
    return [];
  }

  getEstateTypeForUrl(formData) {
    const estateType = formData.get('typeId');

    if(isNullOrWhiteSpace(estateType)) {
      return null;
    }

    const estateTypesContainer = this.#form.querySelector('#estate-types');
    const estateTypeCheckbox = estateTypesContainer.querySelector(`input[value="${estateType}"]`);
    const estateTypeElement = estateTypeCheckbox.parentElement;

    return this.getCleanedUrlPart(estateTypeElement.textContent);
  }

  getCleanedUrlPart(label) {
    if(!label) {
      return null;
    }

    return label
      .trim()
      .replace(/\(.*?\)/g, '')
      .replace(/\s+/g, '-')
      .replace(/-$/, '');
  }

  #toQueryString(formData) {
    const params = new URLSearchParams(formData);

    const keys = [...params.keys()];
    if(keys.length === 0) {
      return '';
    }

    return '?' + params.toString();
  }

  // Exposed only for testing purposes..
  buildUrl(/** @type {FormData} */ formData) {
    return this.#buildUrl(formData);
  }
}
