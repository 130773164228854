// Polyfill needeed for Safari 13...
import EventTarget from '@ungap/event-target';

export default class ScrollManager extends EventTarget {
  #scrolledElement = null;
  #measuredElement = null;
  #lastScrollPosition = 0;
  #lastSwitchPosition = 0;
  #scrollDirection = 'none';
  #boundOnScroll = null;

  constructor(scrolledElement, measuredElement) {
    super();

    if(!scrolledElement || !measuredElement) {
      throw new Error('scrolledElement and measuredElement is required.');
    }

    this.#boundOnScroll = this.#onScroll.bind(this);

    this.#scrolledElement = scrolledElement;
    this.#scrolledElement.addEventListener('scroll', this.#boundOnScroll, { passive: true });
    this.#measuredElement = measuredElement;
  }

  #onScroll() {
    const scrollTop = this.#measuredElement.scrollTop;
    this.#recordScrollPosition(scrollTop);
  }

  #recordScrollPosition(scrollPosition) {
    const lastPosition = this.#lastScrollPosition;
    const lastScrollDirection = this.#scrollDirection;

    let scrollDirection = 'none';
    if(scrollPosition > lastPosition) {
      scrollDirection = 'down';
    } else if(scrollPosition < lastPosition) {
      scrollDirection = 'up';
    }

    if(scrollDirection !== lastScrollDirection) {
      this.#lastSwitchPosition = lastPosition;
    }

    this.#scrollDirection = scrollDirection;
    this.#lastScrollPosition = scrollPosition;

    const event = new CustomEvent('scroll', { detail: {
      scrollPosition,
      scrollDirection: this.scrollDirection,
      scrolledDistance: this.scrolledDistance
    }});

    this.dispatchEvent(event);
  }

  get scrollDirection() {
    return this.#scrollDirection;
  }

  /**
   * @returns {number} The number of pixels scrolled in the current direction.
   */
  get scrolledDistance() {
    return Math.abs(this.#lastScrollPosition - this.#lastSwitchPosition);
  }

  destroy() {
    this.#scrolledElement.removeEventListener('scroll', this.#boundOnScroll);
  }
}
