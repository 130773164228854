import postbox from '../../postbox.mjs';
import { selectTab } from '../../page-scripts/tabs.mjs';

export default () => {

  postbox.addEventListener('dialog-opened', ({ detail: { dialog, sourceElement } }) => {
    if(dialog.element.id !== 'image-dialog') {
      return;
    }

    const selectTabId = sourceElement.dataset.selectTab;
    const tabList = dialog.element.querySelector('[role="tablist"]');
    const tab = tabList?.querySelector(`[role="tab"][id="${selectTabId}"`);
    if(!tab) {
      return;
    }

    selectTab(tab, true);

    const scrollAnchor = sourceElement.dataset.scrollAnchor;
    if(!scrollAnchor) {
      return;
    }

    const targetImage = document.getElementById(scrollAnchor);
    if(!targetImage) {
      return;
    }

    targetImage.scrollIntoView({ behavior: 'instant' });
  });

  postbox.addEventListener('tab-selected', ({ detail: { tablist } }) => {
    if(tablist.id !== 'image-dialog-tablist') {
      return;
    }

    const dialogContent = document.getElementById('image-dialog-content');
    if(!dialogContent) {
      return;
    }

    dialogContent.scrollTop = 0;
  });
};
