import postbox from '../../postbox.mjs';

export default class CreateSearchAlertButtonHandler {
  /**
   * @type {HTMLButtonElement}
   */
  #button;

  constructor(containerElement) {
    const button = containerElement.querySelector('#search-alert-button');

    if(!button) {
      console.log('No search alert button found on page, most likely because there is no search alert form on the page.');
      return;
    }

    this.#button = button;

    this.#init();
  }

  #init() {
    postbox.addEventListener('toggle-search-alert-button', this.#handleToggleEvent.bind(this));
  }

  #handleToggleEvent(/** @type {CustomEvent} */ event) {
    const enableButton = event.detail.enabled;
    const disabledTooltip = this.#button.dataset.disabledTooltip;

    if(enableButton) {
      this.#button.disabled = false;
      this.#button.title = '';
    } else {
      this.#button.disabled = true;
      this.#button.title = disabledTooltip;
    }
  }
}
