/**
 * @param {Element} rootElement
 */
export default async(rootElement = document.body) => {
  const factsRoot = rootElement.querySelector('.facts');

  if(!factsRoot) {
    return;
  }

  factsRoot.addEventListener('click', event => {
    const targetElement = event.target;
    const listItem = targetElement.closest('li');

    if(targetElement.nodeName !== 'BUTTON' || !listItem) {
      return;
    }

    toggleElement(listItem);
  });

  const hiddenElements = factsRoot.querySelectorAll('.section-facts[hidden="until-found"]');
  for(const hiddenElement of hiddenElements) {
    hiddenElement.addEventListener('beforematch', event => {
      const targetElement = event.target;
      const listItem = targetElement.closest('li');

      toggleElement(listItem);
    });
  }
};

const toggleElement = listItem => {
  const button = listItem.querySelector(':scope > .section-type button');
  const factsElement = listItem.querySelector('.section-facts');
  if(!factsElement || !button) {
    return;
  }

  if(factsElement.hasAttribute('hidden')) {
    factsElement.removeAttribute('hidden');
    button.setAttribute('aria-expanded', 'true');
  } else {
    factsElement.setAttribute('hidden', 'until-found');
    button.setAttribute('aria-expanded', 'false');
  }
};
