import { VimeoVideo } from './vimeo-video.mjs';
import { readFromCache, writeToCache } from './local-cache.mjs';

const getThumbnailUrl = async vimeoIdOrUrl => {
  const vimeoVideo = VimeoVideo.tryParse(vimeoIdOrUrl);
  if(!vimeoVideo) {
    return null;
  }

  const cacheKey = `vimeo-thumbnail-${vimeoVideo.watchUrl}`;
  const cachedThumbnailUrl = readFromCache(cacheKey);
  if(!!cachedThumbnailUrl) {
    return cachedThumbnailUrl;
  }

  const thumbnailUrl = await vimeoVideo.getThumbnailUrl();
  writeToCache(cacheKey, thumbnailUrl);

  return thumbnailUrl;
};

export { getThumbnailUrl };
