/**
 * Verifies that a property exists deep down on an object
 * @param {object} obj The root object to check from
 * @param {string} current The first property name to check
 * @param  {...string} rest The rest of the property names to check
 * @returns {boolean} True if the property exists, false otherwise
 */
export let verifyPropertyExists = (obj, current, ...rest) => {
  if(typeof obj === 'undefined') {
    return false;
  }

  if(rest.length === 0 && Object.prototype.hasOwnProperty.call(obj, current)) {
    return true;
  }

  return verifyPropertyExists(obj[current], ...rest);
};
