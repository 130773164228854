import { firstAnscestorOrDefault } from '../utils/dom-utils.mjs';
import { debounce } from '../utils/debounce.mjs';

/**
 * @param {Element} rootElement
 */
export default function apply(rootElement = document.body) {
  const calculateButtonVisibility = (button, content) => {
    const contentHeight = content.offsetHeight;
    const contentScrollHeight = content.scrollHeight;

    if(contentHeight < contentScrollHeight) {
      button.style.display = null;
    } else {
      button.style.display = 'none';
    }
  };

  const initAccordions = (accordions = [...rootElement.querySelectorAll('.read-more-accordion')]) => {
    accordions.forEach(accordion => {
      const button = accordion.querySelector('button');
      const content = accordion.querySelector('.content');

      if(!button || !content) {
        console.warn('Invalid markup for read more accordion. Could not find button or content element.', accordion);
        return;
      }

      calculateButtonVisibility(button, content);
    });
  };

  const initObserver = () => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(mutation => {
        if(mutation.type === 'childList') {
          const elements = [...mutation.addedNodes]
            .filter(node => node.nodeType === Node.ELEMENT_NODE) // Filter out non-element nodes such as text nodes and comments
            .filter(element => !!firstAnscestorOrDefault(element, element => element.classList.contains('read-more-accordion')) || !!element.querySelector('.read-more-accordion'));

          const accordions = elements
            .flatMap(element => [
              firstAnscestorOrDefault(element, element => element.classList.contains('read-more-accordion')),
              ...element.querySelectorAll('.read-more-accordion')
            ])
            .filter(accordionElement => !!accordionElement);
          initAccordions(accordions);
        }
      });
    });

    observer.observe(rootElement, { subtree: true, childList: true });
  };

  const handleClick = event => {
    /**
     * @type {HTMLElement}
     */
    const clickedElement = event.target;

    if(clickedElement.nodeName !== 'BUTTON' && clickedElement.nodeName !== 'SPAN') {
      return;
    }

    const button = clickedElement.nodeName === 'BUTTON' ? clickedElement : clickedElement.parentElement;
    if(!button) {
      return;
    }

    const accordionContainer = firstAnscestorOrDefault(clickedElement, element => element.classList.contains('read-more-accordion'));
    if(!accordionContainer) {
      return;
    }

    const accordionContent = accordionContainer.querySelector('.content');
    if(!accordionContent) {
      console.warn('Invalid markup for read more accordion. Could not find content element.', accordionContainer);
      return;
    }

    const isExpanded = button.getAttribute('aria-expanded') === 'true';
    button.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
    accordionContainer.classList.toggle('expanded', !isExpanded);

    event.preventDefault();
    event.stopPropagation();
  };

  rootElement.addEventListener('click', handleClick);

  setTimeout(() => {
    initAccordions();
    initObserver();
  }, 250);

  window.addEventListener('resize', debounce(() => {
    initAccordions();
  }, 100), true);
}
