import postbox from '../postbox.mjs';
import { firstAnscestorOrDefault } from './dom-utils.mjs';
import { lockFocus, unlockFocus } from './lock-focus.mjs';
import { lockScroll, unlockScroll } from './scroll-utils.mjs';
import { isNullOrWhiteSpace } from './string-utils.mjs';

export default class Dialog {
  /**
   * @type {HTMLElement}
   */
  #targetElement = null;

  /**
   * @type {HTMLElement}
   */
  #openedFromElement = null;

  #isOpen = false;

  #handleClickBound = null;
  #handleKeyDownBound = null;

  get element() {
    return this.#targetElement;
  }

  constructor(targetElement) {
    if(!targetElement) {
      throw new Error('targetElement is required.');
    }

    if(isNullOrWhiteSpace(targetElement.id)) {
      throw new Error('targetElement must have an id.');
    }

    this.#targetElement = targetElement;
    this.#handleClickBound = this.#handleClick.bind(this);
    this.#handleKeyDownBound = this.#handleKeyDown.bind(this);

    this.#initElements();
  }

  #initElements() {
    const targetElement = this.#targetElement;
    targetElement.setAttribute('role', 'dialog');
    targetElement.setAttribute('aria-modal', 'true');
    targetElement.setAttribute('tabindex', '-1');

    const header = targetElement.querySelector('h2');
    if(!!header) {
      if(isNullOrWhiteSpace(header.id)) {
        header.setAttribute('id', this.#generateUniqueElementId(`${targetElement.id}-header`));
      }

      targetElement.setAttribute('aria-labelledby', header.id);
    }

    const activatorElements = this.#getActivatorElements();
    for(const element of activatorElements) {
      element.setAttribute('aria-controls', targetElement.id);
      element.setAttribute('aria-expanded', 'false');
    }

    targetElement.addEventListener('click', this.#handleClickBound);
    targetElement.addEventListener('keydown', this.#handleKeyDownBound);
  }

  destroy() {
    this.#targetElement.removeEventListener('click', this.#handleClickBound);
    this.#targetElement.removeEventListener('keydown', this.#handleKeyDownBound);

    if(this.#isOpen) {
      unlockFocus();
      unlockScroll();
    }
  }

  #handleClick(event) {
    const targetElement = event.target;
    const elementWithDialogClose = firstAnscestorOrDefault(targetElement, element => typeof element.dataset.dialogCloseButton !== 'undefined', true);

    if(!!elementWithDialogClose) {
      this.close();
    }
  }

  #handleKeyDown(event) {
    const key = event.key;

    if(key === 'Escape') {
      this.close();
    }
  }

  open(sourceElement) {
    this.#openedFromElement = sourceElement;

    this.#isOpen = true;
    this.#updateElements();
  }

  close() {
    this.#isOpen = false;
    this.#updateElements();

    postbox.dispatchEvent(new CustomEvent('dialog-closed', { detail: { dialog: this } }));
  }

  #updateElements() {
    const isOpen = this.#isOpen;
    const targetElement = this.#targetElement;
    targetElement.classList.toggle('open', isOpen);

    if(isOpen) {
      lockFocus(targetElement);
      lockScroll();
      targetElement.focus();
    } else {
      unlockFocus();
      unlockScroll();
      this.#openedFromElement?.focus();
    }

    const activatorElements = this.#getActivatorElements();
    for(const activatorElement of activatorElements) {
      activatorElement.setAttribute('aria-expanded', isOpen.toString());
    }
  }

  #generateUniqueElementId(baseId) {
    let id = baseId;
    let i = 1;

    while(!!document.getElementById(id)) {
      id = `${baseId}-${i++}`;
    }

    return id;
  }

  #getActivatorElements() {
    const targetElement = this.#targetElement;
    const elements = [...document.querySelectorAll('[data-dialog-activator]')];
    return [
      ...elements.filter(element => element.dataset.dialogActivator === targetElement.id),
      ...this.#getCloseButtons()
    ];
  }

  #getCloseButtons() {
    const targetElement = this.#targetElement;
    const elements = [...document.querySelectorAll('[data-dialog-close-button]')];
    return elements.filter(element => element.dataset.dialogcCloseButton === targetElement.id || targetElement.contains(element));
  }
}
