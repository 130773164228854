import InternationalAreaCombobox from './international-area-combobox.mjs';
import CountrySelectWrapper from './country-select-wrapper.mjs';
import { firstAnscestorOrDefault } from '../../utils/dom-utils.mjs';
import InternationalSearchFormHandler from './international-search-form-handler.mjs';

/**
 * @param {Element} pageElement The estate search page root element
 */
export default (pageElement) => {
  if(!pageElement) {
    return;
  }

  const countrySelectElement = pageElement.querySelector('#country');
  const countrySelect = new CountrySelectWrapper(countrySelectElement);

  const provinceComboboxElement = pageElement.querySelector('#multiselect-province-combobox');
  const provinceCombobox = getCombobox(getComboboxContainerElement(provinceComboboxElement));

  const cityComboboxElement = pageElement.querySelector('#multiselect-city-combobox');
  const cityCombobox = getCombobox(getComboboxContainerElement(cityComboboxElement));

  const cityAreaComboboxElement = pageElement.querySelector('#multiselect-city-area-combobox');
  const cityAreaCombobox = getCombobox(getComboboxContainerElement(cityAreaComboboxElement));

  const multiselects = [
    { name: 'country', combobox: countrySelect, dependsOn: [ provinceCombobox, cityCombobox, cityAreaCombobox] },
    { name: 'province', combobox: provinceCombobox, dependsOn: [ cityCombobox, cityAreaCombobox] },
    { name: 'city', combobox: cityCombobox, dependsOn: [ cityAreaCombobox ] },
    { name: 'city-area', combobox: cityAreaCombobox, dependsOn: [] }
  ];

  // eslint-disable-next-line no-unused-vars
  const formHandler = new InternationalSearchFormHandler(pageElement, multiselects);
};

const getComboboxContainerElement = (element) => {
  return firstAnscestorOrDefault(element, x => x.classList.contains('multiselect'));
};

const getCombobox = (containerElement) => {
  const comboboxElement = containerElement.querySelector('.multiselect-combobox');
  const listboxElement = containerElement.querySelector('.multiselect-listbox');
  const inputContainerElement = containerElement.querySelector('.input-container');

  const combobox = new InternationalAreaCombobox(comboboxElement, listboxElement, inputContainerElement);

  return combobox;
};
