/**
 * Apply classes to trigger animations on elements.
 */
export default function applyAnimations() {
  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  if(prefersReducedMotion) {
    return;
  }

  setupEnterScreenAnimations();
  setupHeroAnimations();
  setupBrokerProfileAnimations();
}

const setupEnterScreenAnimations = () => {
  const elementsToFadeUpOnEnterScreen = document.querySelectorAll([
    // General
    '.office-brokers-block .broker-item',
    '.content-with-image-block',
    '.estate-list-block .estate-search-result-item',
    '.selling-point-block',
    '.guide-step-block',
    // Estate details
    '.brokers-and-showings',
    '.estate-images li',
    '.floor-plan li',
    '.map-section iframe',
    '.broker-profiles-section li'
  ].join(', '));

  elementsToFadeUpOnEnterScreen.forEach((element) => {
    element.style.visibility = 'hidden';
  });

  triggerOnEnterScreen((element) => {
    element.style.visibility = 'visible';
    animate(element, 'fade-up');
  }, elementsToFadeUpOnEnterScreen);
};

const setupHeroAnimations = () => {
  const hero = document.querySelector('.hero-container');
  if(hero === null) {
    return;
  }

  const heading = hero.querySelector('h1');
  const ingress = hero.querySelector('p');
  const interactionContainer = hero.querySelector('.interactions-container');

  animate(heading, 'clip-right', 100);
  animate(ingress, 'clip-right', 100);
  animate(interactionContainer, 'fade-up', 1000);
};

const setupBrokerProfileAnimations = () => {
  const brokerPageProfile = document.querySelector('.broker-page .broker-profile');
  if(brokerPageProfile === null) {
    return;
  }

  const brokerProfileHeading = brokerPageProfile.querySelector('h1');
  const brokerProfileInfo =
    brokerPageProfile.querySelectorAll('.profile-info :not(h1), .broker-profile-cta-container');

  animate(brokerProfileHeading, 'clip-right', 100);
  brokerProfileInfo.forEach((element, index) => {
    animate(element, 'fade-up', 600 + (index * 100));
  });
};

/**
 * @param {Element} element Element to animate
 * @param {string} animation Name of animation class to apply
 * @param {number} delay Delay in milliseconds before applying animation
 */
const animate = (element, animation, delay = 0) => {
  if(element === null) {
    return;
  }

  if(delay === 0) {
    element.classList.add(animation);
    return;
  }

  element.style.visibility = 'hidden';
  setTimeout(() => {
    element.style.visibility = 'visible';
    element.classList.add(animation);
  }, delay);
};

/**
 * @param {Function} enterScreenCallback Function to call when element enters screen
 * @param {Element[]} elements Elements to observe
 */
const triggerOnEnterScreen = (enterScreenCallback, elements) => {
  const enterScreenObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting) {
        const element = entry.target;
        enterScreenCallback(element);
        enterScreenObserver.unobserve(element);
      }
    });
  });

  elements.forEach((element) => {
    enterScreenObserver.observe(element);
  });
};
