import ScrollManager from '../utils/scroll-manager.mjs';

/**
 * @param {Element|Document} scrolledElement Element that is scrolled
 * @param {Element} measuredElement Element that is measured for scroll position
 * @param {Element} stickyElement Element that is made sticky
 * @param {number} breakpoint Number of pixels from the top to scroll before the sticky menu is enabled
 * @param {number} showAfter Number of pixels to scroll upwards before the sticky menu is shown
 * @param {number} hideAfter Number of pixels to scroll downwards before the sticky menu is hidden
 */
export default function apply(
  scrolledElement = document,
  measuredElement = document.documentElement,
  stickyElement = document.getElementById('main-header'),
  breakpoint = 150,
  showAfter = 70,
  hideAfter = 70) {

  if(!scrolledElement || !measuredElement || !stickyElement) {
    console.error('scrolledElement, measuredElement and stickyElement are required.');
    return;
  }

  const manager = new ScrollManager(scrolledElement, measuredElement);

  manager.addEventListener('scroll', /** @param {CustomEvent} scrollEvent */ (scrollEvent) => {
    const {
      scrollPosition,
      scrollDirection,
      scrolledDistance
    } = scrollEvent.detail;

    if(scrollPosition > breakpoint) {
      stickyElement.classList.add('sticky');
    } else if(scrollPosition === 0) {
      stickyElement.classList.remove('sticky');
    }

    if(scrollDirection === 'up' && scrolledDistance > showAfter) {
      stickyElement.classList.add('show-sticky');
    }

    if(scrollDirection === 'down' && scrolledDistance > hideAfter) {
      stickyElement.classList.remove('show-sticky');
    }
  });
}
