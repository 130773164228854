import applyAnchorContainer from './anchor-container.mjs';
import applyAnimations from './animate.mjs';
import applyAutoPlayVideo from './autoplay-video.mjs';
import applyClickableContainers from './clickable-containers.mjs';
import applyCookieTractor from './cookie-tractor.mjs';
import applyGoogleTagManagerEvents from './google-tag-manager-events.mjs';
import applyLanguageSelectors from './language-selector.mjs';
import applyOverlayScripts from './overlays.mjs';
import applyReadMoreAccordion from './read-more-accordion.mjs';
import applyStickyHeader from './sticky-header.mjs';
import applyTabs from './tabs.mjs';
import applyToggleOpen from './toggle-open.mjs';

/**
 * Init all page scripts
 */
export default function() {
  applyAnchorContainer();
  applyAnimations();
  applyAutoPlayVideo();
  applyClickableContainers();
  applyCookieTractor();
  applyGoogleTagManagerEvents();
  applyLanguageSelectors();
  applyOverlayScripts();
  applyReadMoreAccordion();
  applyStickyHeader();
  applyTabs();
  applyToggleOpen();
}
