// Polyfill needeed for Safari 13...
import EventTarget from '@ungap/event-target';
import escapeStringRegexp from 'escape-string-regexp';
import { firstAnscestorOrDefault } from './dom-utils.mjs';

const SPACE_KEY = ' ';

export default class Combobox extends EventTarget {
  /**
   * @type {HTMLElement}
   */
  #comboboxElement;

  /**
   * @type {HTMLElement}
   */
  #listboxElement;

  #selectedIndex = -1;
  #isOpen = false;

  get comboboxElement() {
    return this.#comboboxElement;
  }

  get listboxElement() {
    return this.#listboxElement;
  }

  /**
   * @param {HTMLElement} comoboxElement
   * @param {HTMLElement} listboxElement
   */
  constructor(comoboxElement, listboxElement) {
    super();

    if(!(comoboxElement instanceof HTMLElement)) {
      throw new Error('comboboxElement must be an HTMLElement.');
    }

    if(!(listboxElement instanceof HTMLElement)) {
      throw new Error('listboxElement must be an HTMLElement.');
    }

    this.#comboboxElement = comoboxElement;
    this.#listboxElement = listboxElement;

    this.#comboboxElement.parentElement.classList.add('js-enhanced');

    this.#setupEventListeners();
  }

  get #visibleListboxItems() {
    return [...this.#listboxElement.querySelectorAll('[role="option"]:not([aria-hidden="true"])')];
  }

  get #listboxItems() {
    return [...this.#listboxElement.querySelectorAll('[role="option"]')];
  }

  get #listboxGroups() {
    return [...this.#listboxElement.querySelectorAll('[role="group"]')];
  }

  get #isMultiselect() {
    return this.#listboxElement.getAttribute('aria-multiselectable') === 'true';
  }

  get #isDisabled() {
    return this.#comboboxElement.getAttribute('aria-disabled') === 'true';
  }

  get #supportsFiltering() {
    const comboboxElement = this.#comboboxElement;
    return comboboxElement instanceof HTMLInputElement && comboboxElement.dataset.supportsFiltering === 'true';
  }

  #setupEventListeners() {
    const comboboxElement = this.#comboboxElement;
    const listboxElement = this.#listboxElement;

    comboboxElement.addEventListener('keydown', this.#handleKeyDown.bind(this));

    comboboxElement.addEventListener('focus', () => {
      this.#updateARIAAttributes();
    });

    comboboxElement.addEventListener('click', () => {
      this.open();
      this.#updateARIAAttributes();
    });

    comboboxElement.addEventListener('blur', e => {
      const newFocus = e.relatedTarget;
      if(listboxElement.contains(newFocus)) {
        this.#comboboxElement.focus();
        return;
      }

      // Delay this a tiny bit to not interfere with the click event
      window.setTimeout(() => {
        this.#reset();
      }, 250);
    });

    listboxElement.addEventListener('click', this.#handleOptionClick.bind(this));

    if(this.#supportsFiltering) {
      comboboxElement.addEventListener('input', this.#handleFilterInput.bind(this));
    }
  }

  open() {
    if(this.#isDisabled) {
      return;
    }

    this.#isOpen = true;

    this.#updateVisibilityAttributes();
    this.#updateScrollPosition();
  }

  close() {
    this.#isOpen = false;
    this.#selectedIndex = -1;

    if(this.#supportsFiltering) {
      this.#comboboxElement.value = '';
      this.#handleFilterInput({ target: this.#comboboxElement });
    }

    this.#updateARIAAttributes();
    this.#updateVisibilityAttributes();
  }

  #reset() {
    this.close();

    this.#updateARIAAttributes();
    this.#updateScrollPosition();
  }

  /**
   * @param {KeyboardEvent} event
   */
  #handleKeyDown(event) {
    if(this.#isDisabled) {
      return;
    }

    this.open();

    const suggestions = this.#visibleListboxItems;
    const currentlySelectedIndex = this.#selectedIndex;

    let newIndex = currentlySelectedIndex;
    switch (event.key) {
      case 'ArrowDown':
        newIndex++;
        break;
      case 'ArrowUp':
        newIndex--;
        break;
      case SPACE_KEY:
        if(this.#isOpen) {
          // Return here to not prevent the default action of the space key
          // when the combobox is is already open
          return;
        }

        this.open();
        this.#updateARIAAttributes();

        break;
      case 'Escape':
        this.#reset();
        return;
      case 'Enter':
        this.#handleEnterKey(event);
        return;
      case 'Tab':
        if(this.#isMultiselect) {
          this.close();
          return;
        }

        // If we tab backwards we should not select anything
        if(!event.shiftKey) {
          this.#handleEnterKey(event);
        }

        this.#reset();
        return;
      default:
        return;
    }

    event.preventDefault();

    if(newIndex > suggestions.length - 1) {
      newIndex = suggestions.length - 1;
    } else if(newIndex < 0) {
      newIndex = -1;
    }

    this.#selectedIndex = newIndex;

    this.#updateScrollPosition();
    this.#updateARIAAttributes();
  }

  /**
   * @param {InputEvent} event
   */
  #handleFilterInput(event) {
    const filterText = event.target.value.trim();
    const items = this.#listboxItems;

    const hasFilterText = filterText.length > 0;
    const filterRegex = new RegExp('^' + escapeStringRegexp(filterText), 'i');

    for(const item of items) {
      const text = item.textContent.trim();
      const matches = hasFilterText ? filterRegex.test(text) : true;

      item.setAttribute('aria-hidden', matches ? 'false' : 'true');
    }

    const itemGroups = this.#listboxGroups;
    for(const group of itemGroups) {
      const visibleItems = [...group.querySelectorAll('[role="option"]:not([aria-hidden="true"])')];
      group.setAttribute('aria-hidden', visibleItems.length === 0 ? 'true' : 'false');
    }

  }

  /**
   * @param {MouseEvent} event
   */
  #handleOptionClick(event) {
    if(this.#isDisabled) {
      return;
    }

    const target = firstAnscestorOrDefault(event.target, element => element.getAttribute('role') === 'option', true);
    if(!target) {
      return;
    }

    const items = this.#visibleListboxItems;
    const clickedItem = items.find(item => item.id === target.id);

    if(clickedItem.getAttribute('aria-disabled') === 'true') {
      return;
    }

    this.#handleItemSelectedEvent(clickedItem);

    if(!this.#isMultiselect) {
      this.#reset();
    }
  }

  #handleEnterKey(event) {
    const items = this.#visibleListboxItems;
    const selectedIndex = this.#selectedIndex;

    if(selectedIndex === -1) {
      return;
    }

    event.preventDefault();

    const selecteditem = items[selectedIndex];

    if(selecteditem.getAttribute('aria-disabled') === 'true') {
      return;
    }

    this.#handleItemSelectedEvent(selecteditem);

    this.#updateARIAAttributes();

    if(!this.#isMultiselect) {
      this.#reset();
    }
  }

  /**
   * @param {HTMLElement} itemElement
   */
  #handleItemSelectedEvent(itemElement) {
    let eventName = 'item-selected';
    if(this.#isMultiselect) {
      const isSelected = itemElement.getAttribute('aria-selected') === 'true';
      itemElement.setAttribute('aria-selected', isSelected ? 'false' : 'true');

      if(isSelected) {
        eventName = 'item-deselected';
      }
    }

    const event = new CustomEvent(eventName, {
      detail: {
        sender: this,
        element: itemElement
      }
    });

    this.dispatchEvent(event);
  }

  // 2023-09-04 KJ Code coverage disabled since scroll interactions aren't really testable using JSDOM
  // wihtout mocking essentially everyting.
  #updateScrollPosition() /* istanbul ignore next */ {
    const listboxElement = this.#listboxElement;

    const style = window.getComputedStyle(listboxElement);
    if(style.overflowY !== 'scroll') {
      return;
    }

    if(this.#selectedIndex === -1) {
      listboxElement.scrollTop = 0;
      return;
    }

    const items = this.#visibleListboxItems;
    const selectedItem = items[this.#selectedIndex];

    const scrollHeight = listboxElement.scrollHeight;
    const listboxHeight = listboxElement.clientHeight;

    if(scrollHeight < listboxHeight) {
      return;
    }

    const scrollTop = listboxElement.scrollTop;
    const itemTop = selectedItem.offsetTop;
    const itemBottom = itemTop + selectedItem.offsetHeight;
    const listboxBottom = listboxHeight + scrollTop;

    if(itemBottom > listboxBottom) {
      listboxElement.scrollTop = itemBottom - listboxHeight;
    } else if(itemTop < scrollTop) {
      listboxElement.scrollTop = itemTop;
    }
  }

  #updateARIAAttributes() {
    const comboboxElement = this.#comboboxElement;
    const items = this.#visibleListboxItems;

    this.#updateVisibilityAttributes();

    if(this.#selectedIndex === -1) {
      comboboxElement.removeAttribute('aria-activedescendant');
    } else {
      const selectedItem = items[this.#selectedIndex];
      comboboxElement.setAttribute('aria-activedescendant', selectedItem.id);
    }

    for(const [index, itemElement] of items.entries()) {
      if(!this.#isMultiselect) {
        itemElement.setAttribute('aria-selected', index === this.#selectedIndex ? 'true' : 'false');
      } else {
        itemElement.classList.toggle('multiselect-current', index === this.#selectedIndex);
      }
    }
  }

  #updateVisibilityAttributes() {
    const comboboxElement = this.#comboboxElement;
    const listboxElement = this.#listboxElement;
    const items = this.#visibleListboxItems;

    const showPanel = this.#isOpen && items.length > 0;

    comboboxElement.setAttribute('aria-expanded', showPanel > 0 ? 'true' : 'false');
    listboxElement.classList.toggle('open', showPanel);
  }
}
