/* global $$epiforms */
import { verifyPropertyExists } from '../utils/verify-property-exists.mjs';
import configuration from '../configuration.mjs';
import TagManager from '../utils/google-tag-manager.mjs';

export default () => {
  if(typeof $$epiforms === 'function') {
    $$epiforms(document).ready(() => {
      $$epiforms('.EPiServerForms').on('formsNavigateToStep', handleFormsNavigateToStep);
      $$epiforms('.EPiServerForms').on('formsStartSubmitting', handleFormsStartSubmitting);
      $$epiforms('.EPiServerForms').on('formsSubmitted', handleFormsSubmitted);
    });
  }

  // Disable the form scrolling to the top after every step
  if(verifyPropertyExists(window, 'epi', 'EPiServer', 'Forms', 'Utils', 'scrollToTheTopOfForm')) {
    window.epi.EPiServer.Forms.Utils.scrollToTheTopOfForm = () => { };
  }

  // Enable async submit for forms
  if(verifyPropertyExists(window, 'epi', 'EPiServer', 'Forms')) {
    window.epi.EPiServer.Forms.AsyncSubmit = true;
  }
};

export const handleFormsNavigateToStep = event => {
  const form = event.currentTarget;
  const stepContainer = form.previousElementSibling;
  if(stepContainer?.matches('.step-container') !== true) {
    console.warn('Could not find step container for form', form);
    return;
  }

  const currentLabel = stepContainer.dataset.currentLabel;
  const doneLabel = stepContainer.dataset.doneLabel;

  const stepList = stepContainer.querySelector('ol');

  const currentIndex = event.targetStep.index;
  const stepElements = [...stepList.children];
  for(const stepElement of stepElements) {
    const isCurrentStep = stepElements.indexOf(stepElement) === currentIndex;
    stepElement.classList.toggle('current', isCurrentStep);

    const stepStateElement = stepElement.querySelector('.step-state');
    if(!stepStateElement) {
      continue;
    }

    const isDoneStep = stepElements.indexOf(stepElement) < currentIndex;
    if(isCurrentStep) {
      stepStateElement.textContent = currentLabel;
    } else if(isDoneStep) {
      stepStateElement.textContent = doneLabel;
    } else {
      stepStateElement.textContent = '';
    }
  }
};

export const handleFormsStartSubmitting = event => {
  if(!verifyPropertyExists(window, 'epi', 'EPiServer', 'Forms')) {
    return;
  }

  const formElement = event.currentTarget;

  if(!formElement) {
    return;
  }

  const hiddenElement = formElement.querySelector(`input[data-search-query-element]`);

  if(!hiddenElement) {
    return;
  }

  // This is somewhat of a workaround to get the field name for the search query
  // element since hiddens elements for some reason are not included in the
  // elementInfo data.
  const hiddenElementName = hiddenElement.getAttribute('name');
  event.formData.set(hiddenElementName, window.location.search);
};

export const handleFormsSubmitted = event => {
  if(!event.isFinalizedSubmission) {
    // This is event is fired when a multi step form goes to the next step..
    return;
  }

  const formElement = event.currentTarget;
  if(!formElement) {
    return;
  }

  const formContainer = formElement.closest('.form-container');

  if(!formContainer) {
    return;
  }

  const formType = formContainer.dataset.formType ?? 'unknown';
  const formLeadType = formContainer.dataset.formLeadType;

  const properties = {
    officeId: configuration.office?.id,
    officeName: configuration.office?.name,
    brokerId: configuration.broker?.id,
    brokerName: configuration.broker?.name,
    estateId: configuration.estate?.id,
    estateShortId: configuration.estate?.shortId,
    culture: configuration.culture,
    leadType: formLeadType
  };

  TagManager.trackEvent(`${formType}-form`, properties);

  // Book showing form can also send a lead in some cases
  // In that case we want to track that as well
  if(formType === 'book-showing') {
    handleBookShowingLead(formContainer, event.formData);
  }
};

/**
 * @param {HTMLElement} formContainer The form container
 * @param {FormData} formData The sent form data
 * @returns {boolean} True if the form sent a lead, false otherwise
 */
export const checkIfBookShowingFormSentLead = (formContainer, formData) => {
  if(!formContainer || formContainer.dataset.formType !== 'book-showing') {
    return false;
  }

  const formElement = formContainer.querySelector('form');

  if(!formElement) {
    return false;
  }

  const forms = window.epi.EPiServer.Forms;
  const currentForm = forms[formElement.id];

  if(!currentForm) {
    return false;
  }

  const elementsInfo = Object
    .keys(currentForm.ElementsInfo)
    .map(key => ({ key: key, ...currentForm.ElementsInfo[key]}));

  const sendLeadsFormFriendlyName = 'sendleadsform';
  const sendLeadsFormElementInfo = elementsInfo.find(x => x.friendlyName?.toLowerCase() === sendLeadsFormFriendlyName);

  if(!sendLeadsFormElementInfo) {
    return false;
  }

  const submittedData = formData.get(sendLeadsFormElementInfo.key);

  return submittedData?.toLocaleLowerCase() === 'true';
};

/**
 * @param {HTMLElement} formContainer The form container
 * @param {FormData} formData The sent form data
 */
export const handleBookShowingLead = (formContainer, formData) => {
  if(!checkIfBookShowingFormSentLead(formContainer, formData)) {
    return;
  }

  const formType = 'lead';
  const formLeadType = formContainer.dataset.formExtraLeadType;

  const properties = {
    officeId: configuration.office?.id,
    officeName: configuration.office?.name,
    brokerId: configuration.broker?.id,
    brokerName: configuration.broker?.name,
    estateId: configuration.estate?.id,
    estateShortId: configuration.estate?.shortId,
    culture: configuration.culture,
    leadType: formLeadType
  };

  TagManager.trackEvent(`${formType}-form`, properties);
};
