const cachePrefix = 'local-cache-';

const pruneCache = () => {
  const now = Date.now();
  Object.keys(localStorage)
    .filter(key => key.startsWith(cachePrefix))
    .forEach(key => {
      try {
        const { expiresAt } = JSON.parse(localStorage.getItem(key));
        if(expiresAt < now) {
          localStorage.removeItem(key);
        }
      } catch(e) {
        console.info('Failed to parse cache item', key, e);
        localStorage.removeItem(key);
      }
    });
};

const readFromCache = (/** @type {string} */ key) => {
  pruneCache();

  const { data } = JSON.parse(localStorage.getItem(cachePrefix + key)) || { data : null };

  return data;
};

const writeToCache = (/** @type {string} */ key, data, expireInMinutes = 1440) =>
  localStorage.setItem(cachePrefix + key, JSON.stringify({ expiresAt: Date.now() + (expireInMinutes * 60 * 1000), data }));

export { pruneCache, readFromCache, writeToCache };
