import postbox from '../../postbox.mjs';
import configuration from '../../configuration.mjs';
import { verifyPropertyExists } from '../../utils/verify-property-exists.mjs';

export default () => {
  postbox.addEventListener('dialog-opened', ({ detail: { dialog, sourceElement } }) => {
    if(dialog.element.id !== 'book-showing-dialog') {
      return;
    }

    if(!verifyPropertyExists(window, 'epi', 'EPiServer', 'Forms')) {
      return;
    }

    const formElement = dialog.element.querySelector('form');

    if(!formElement) {
      return;
    }

    const targetShowingId = sourceElement.dataset.showingId;
    if(!!targetShowingId) {
      preselectOption(formElement, 'ShowingInformation', targetShowingId);
    }

    const officeID = configuration.office?.id;
    if(!!officeID) {
      preselectOption(formElement, 'OfficeId', officeID);
    }
  });
};

/**
 * @param {HTMLElement} formElement
 * @param {string} selectFriendlyName
 * @param {string} targetOptionValue
 */
const preselectOption = (formElement, selectFriendlyName, targetOptionValue) => {
  const forms = window.epi.EPiServer.Forms;
  const currentForm = forms[formElement.id];

  if(!currentForm) {
    return;
  }

  const elementsInfo = Object
    .keys(currentForm.ElementsInfo)
    .map(key => ({ key: key, ...currentForm.ElementsInfo[key]}));

  selectFriendlyName = selectFriendlyName.toLowerCase();

  const selectElementInfo = elementsInfo.find(x => x.friendlyName?.toLowerCase() === selectFriendlyName);

  if(!selectElementInfo) {
    return;
  }

  const selectElement = document.querySelector(`select[name="${selectElementInfo.key}"]`);

  if(!selectElement) {
    return;
  }

  const options = [...selectElement.options];
  const targetOption = options.find(x => x.value.startsWith(targetOptionValue));

  if(!targetOption) {
    return;
  }

  selectElement.value = targetOption.value;
};
