import FreetextAutocompleteCombobox from './freetext-autocomplete-combobox.mjs';
import SwedishSearchFormHandler from './swedish-search-form-handler.mjs';

/**
 * @param {Element} pageElement The estate search page root element
 */
export default (pageElement) => {
  if(!pageElement) {
    return;
  }

  const combobox = pageElement.querySelector('#freetext-autocomplete-combobox');
  const listbox = pageElement.querySelector('#freetext-autocomplete-listbox');

  const autocompleteHandler = new FreetextAutocompleteCombobox(combobox, listbox);
  // eslint-disable-next-line no-unused-vars
  const formHandler = new SwedishSearchFormHandler(pageElement, autocompleteHandler);
};
