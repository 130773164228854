import { firstAnscestorOrDefault } from '../utils/dom-utils.mjs';

const ME_SECONDARY_BUTTON = 2;

/**
 * @param {Element} rootElement
 */
export default function apply(rootElement = document.body) {
  const handleClick = (/** @type {MouseEvent} */ event) => {
    if(event.button === ME_SECONDARY_BUTTON) {
      // Ignore right clicks
      return;
    }

    /**
     * @type {HTMLElement}
     */
    // @ts-ignore
    const clickedElement = event.target;

    const clickTargetAncestor = firstAnscestorOrDefault(clickedElement, element => element.matches('[data-click-target]'));
    if(!clickedElement.matches('[data-click-target]') && !clickTargetAncestor) {
      // No click target found
      return;
    }

    const clickIgnoreAncestor = firstAnscestorOrDefault(clickedElement, element => element.matches('[data-click-ignore]'));
    if(clickedElement.matches('[data-click-ignore]') || !!clickIgnoreAncestor) {
      // Click should be ignored
      return;
    }

    const ownerElement = clickTargetAncestor ?? clickedElement;
    const clickTarget = ownerElement.getAttribute('data-click-target');
    if(!clickTarget) {
      return;
    }

    const targetElement = ownerElement.querySelector(clickTarget);
    if(!targetElement || targetElement === event.target) {
      return;
    }

    const newEvent = new window.MouseEvent('click', {
      view: event.view,
      bubbles: true,
      cancelable: true,
      button: event.button,
      buttons: event.buttons
    });

    targetElement.dispatchEvent(newEvent);

    event.preventDefault();
    event.stopPropagation();
  };

  rootElement.addEventListener('click', handleClick);
  rootElement.addEventListener('mouseup', handleClick);
}
