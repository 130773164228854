import initBrokerSearchPage from './broker-search-page.mjs';
import initEstatePage from './estate-page.mjs';
import initEstateSearchPage from './estate-search-page.mjs';

/**
 * Init all scripts for specific pages
 */
export default function() {
  initBrokerSearchPage();
  initEstatePage();
  initEstateSearchPage();
}
