export class GoogleTagManager {
  #dataLayer;

  constructor() {
    this.#dataLayer = window.dataLayer || (window.dataLayer = []);
  }

  /**
   * @param {string} eventName The camelCased name of the event
   * @param {{[key: string]: string|number}} params The parameters to send with the event
   */
  trackEvent(eventName, params) {
    this.#dataLayer.push({
      event: eventName,
      ...params
    });
  }
}

export default new GoogleTagManager();
