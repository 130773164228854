/**
 * Resets the opacity of overlays to allow transitions to run.
 */
export default function apply() {

  // Overlays have an explicit style attribute to avoid transitions
  // during load.
  const overlayElements = document.querySelectorAll('.initialize-overlay');
  for(const overlayElement of overlayElements) {
    if(!!overlayElement['style']) {
      overlayElement['style'].opacity = null;
    }
  }
}
