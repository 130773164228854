import initializeAnchorContainer, { destroy as destroyAnchorContainer } from '../page-scripts/anchor-container.mjs';
import { debounce } from '../utils/debounce.mjs';
import { getPageLanguage } from '../utils/get-page-language.mjs';

const SEARCH_DEBOUNCE_TIMEOUT = 50;
const LOADER_TIMEOUT = 200;

/**
 * @param {Element} rootElement
 */
export default (rootElement = document.body) => {
  const pageElement = rootElement?.querySelector('#broker-search-page');

  if(!pageElement) {
    return;
  }

  initSearchForm(pageElement);
};

const initSearchForm = pageElement => {
  const searchForm = pageElement.querySelector('form');
  const searchInput = searchForm.querySelector('#query');
  const resetLink = searchForm.querySelector('.reset-link');
  const regionContainer = pageElement.querySelector('.region-group .filters');

  searchForm.addEventListener('keypress', event => {
    // Prevent form submission on enter in the search input
    if(event.key === 'Enter' && event.target === searchInput) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  });

  const getSelectedRegion = () => {
    const selectedRadioButton = pageElement.querySelector('input[name=\'region\']:checked');
    return selectedRadioButton?.value;
  };

  searchInput.addEventListener('keyup', () => {
    const searchTerm = searchInput.value;
    const region = getSelectedRegion();
    debouncedPerformSearch(pageElement, searchTerm, region);
  });

  regionContainer?.addEventListener('click', e => {
    if(e.target.tagName !== 'INPUT') {
      return;
    }

    const searchTerm = searchInput.value;
    const region = getSelectedRegion();
    performSearch(pageElement, searchTerm, region);
  });

  resetLink.addEventListener('click', event => {
    event.stopPropagation();
    event.preventDefault();

    searchInput.value = '';

    const regionFilters = pageElement.querySelectorAll('input[name=\'region\']');
    regionFilters[0].checked = true;

    performSearch(pageElement);
  });
};

const performSearch = async(pageElement, searchTerm = '', region = '') => {
  let doneLoading = false;

  // Only show loader if the search is taking some
  // time so we avoid flickering
  window.setTimeout(() => {
    if(!doneLoading) {
      insertLoader(pageElement);
    }
  }, LOADER_TIMEOUT);

  updateUrl(searchTerm, region);

  const html = await fetchResults(searchTerm, region);

  doneLoading = true;

  if(html === undefined) {
    // Request was aborted, just return and wait for the next one
    return;
  }

  if(html === null) {
    // TODO Make this localizable when get have a service for that
    insertErrorMessage(pageElement, 'An error occured while searching.');
    return;
  }

  destroyAnchorContainer();

  const resultsContainer = pageElement.querySelector('.results');
  resultsContainer.innerHTML = html;

  if(searchTerm.length === 0 && region.length === 0) {
    initializeAnchorContainer();
  }
};

const updateUrl = (searchTerm, region) => {
  const url = new URL(window.location);
  if(searchTerm.length !== 0) {
    url.searchParams.set('query', searchTerm);
  } else {
    url.searchParams.delete('query');
  }
  if(region.length !== 0) {
    url.searchParams.set('region', region);
  } else {
    url.searchParams.delete('region');
  }

  window.history.replaceState({}, '', url);
};

const insertLoader = pageElement => {
  const resultsContainer = pageElement.querySelector('.results');
  resultsContainer.innerHTML = '<span class="loader"></span>';
};

const insertErrorMessage = (pageElement, message) => {
  const resultsContainer = pageElement.querySelector('.results');
  resultsContainer.innerHTML = '';

  const errorMessage = document.createElement('div');
  errorMessage.classList.add('error-message');
  errorMessage.textContent = message;

  resultsContainer.appendChild(errorMessage);
};

const debouncedPerformSearch = debounce(performSearch, SEARCH_DEBOUNCE_TIMEOUT);

/**
 * @type {AbortController}
 */
let abortController = null;

const fetchResults = async(searchTerm = '', region = '') => {
  if(abortController !== null) {
    abortController.abort();
  }

  abortController = new AbortController();
  const signal = abortController.signal;

  const culture = getPageLanguage();
  const url = `/api/${culture}/broker-search/html?query=${encodeURIComponent(searchTerm)}&region=${encodeURIComponent(region)}`;

  let response;
  try {
    response = await fetch(url, { signal });
  } catch(error) {
    if(error.name === 'AbortError') {
      return;
    }

    throw error;
  }

  if(!response.ok) {
    console.error(`Failed to fetch search results: ${response.status} ${response.statusText}`);
    return null;
  }

  return await response.text();
};
