export class VimeoVideo {
  #vimeoId = null;

  constructor(vimeoId) {
    if(!vimeoId) {
      throw new Error('vimeoId is required');
    }

    const parseResult = parseInt(vimeoId, 10);
    if(isNaN(parseResult)) {
      throw new Error(`Failed to parse vimeoId: ${vimeoId}`);
    }

    this.#vimeoId = vimeoId;
  }

  static tryParse(vimeoIdOrUrl) {
    // Matches for strings that contain:
    // * "vimeo.com"
    // * A segment that starts with "/", then has 5+ digits, then possibly ends with a "?"
    // * (Optional) Anything between those two segments
    const match = /vimeo\.com.*?\/(?<vimeoId>\d{5,})/.exec(vimeoIdOrUrl);

    if(!!match) {
      return new VimeoVideo(match.groups.vimeoId);
    }

    return null;
  }

  generateEmbedUrl(autoplay = false) {
    return `https://player.vimeo.com/video/${this.#vimeoId}?autoplay=${autoplay ? 1 : 0}&dnt=1`;
  }

  get watchUrl() {
    return `https://vimeo.com/${this.#vimeoId}`;
  }

  async getEmbedData() {
    const response = await fetch(`https://vimeo.com/api/oembed.json?url=${encodeURIComponent(this.watchUrl)}`);
    if(!response.ok) {
      throw new Error(`Failed to fetch embed data for ${this.watchUrl}`);
    }

    return await response.json();
  }

  async getThumbnailUrl() {
    const data = await this.getEmbedData();
    return data.thumbnail_url;
  }
}
