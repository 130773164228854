/**
 * @param {Function} func The function to debounce
 * @param {number} timeout The timeout until the method is called in milliseconds
 * @returns {Function} The debounced function
 */
export let debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};
